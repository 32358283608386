import axios from 'axios';
import type { PublishedModuleStatus, PublishedModuleType, PublishedModuleVisibility, WbAppType } from '@mst-fe/shared';
import { LinkedError } from '@mst-fe/shared/dist/errors/linked-error';
import { checkAxiosResponse, handleAxiosError } from '@mst-fe/shared/dist/errors/axios-errors';

import { axiosOptions, GenericAPIResponse } from '../../services/requests';
import { ModuleConfigSchema } from './models/my-published-manifests-store';
import { UserData } from '../users/users-api';

export interface PublishedModule {
  id: string;
  name: string;
  description: string;
  publisher: string;
  publishedAt: string;
  tags?: string[];
  configSchema: ModuleConfigSchema;
  type: PublishedModuleType;
  appType?: WbAppType;
  logo?: string;
}

export interface UserPublishedModule {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  tags?: string[];
  configSchema: ModuleConfigSchema;
  wbShortId: string;
  visibility: PublishedModuleVisibility;
  status: PublishedModuleStatus;
  type: PublishedModuleType;
  isUnpublishing?: boolean;
  serviceId?: string;
  appType?: WbAppType;
  manifestId?: string;
  jobId?: string;
  userSubject: string;
  userSubjects: UserData[];
  logo?: string;
}

export interface InstallModuleRequest {
  publishedModuleId: string;
  wbShortId: string | undefined;
  clusterId: string | null | undefined;
  serverId: string | null | undefined;
  config: unknown;
}

export class AppStoreApi {
  async getAllPublishedModules(): Promise<PublishedModule[] | undefined> {
    return await checkAxiosResponse(
      axios.get<PublishedModule[]>(`/api/zoe/app-store/published?publishedOnly=true&types[]=app_code&types[]=task`, await axiosOptions())
    );
  }

  async getUserPublishedModules(): Promise<UserPublishedModule[] | undefined> {
    return await checkAxiosResponse(axios.get<UserPublishedModule[]>(`/api/zoe/app-store/published/user`, await axiosOptions()));
  }

  async installModule(req: InstallModuleRequest): Promise<GenericAPIResponse | undefined> {
    const responseOrError = await handleAxiosError(axios.post<GenericAPIResponse>('/api/zoe/app-store/install', req, await axiosOptions()));
    if (responseOrError instanceof LinkedError) {
      if (responseOrError.cause.responseStatus === 400) {
        return responseOrError.cause.responseData as GenericAPIResponse;
      }

      return undefined;
    }

    return responseOrError;
  }

  async updatePublishedModule(module: UserPublishedModule): Promise<GenericAPIResponse | undefined> {
    const { wbShortId } = module;
    const awsAppShortName = window.CONFIG.awsAppShortName;

    const responseOrError = await handleAxiosError<GenericAPIResponse>(
      axios.post(`/api/zoe/app-store/${awsAppShortName}${wbShortId}/publish`, module, await axiosOptions())
    );
    if (responseOrError instanceof LinkedError) {
      if (responseOrError.cause.responseStatus === 400) {
        return responseOrError.cause.responseData as GenericAPIResponse;
      }

      return undefined;
    }

    return responseOrError;
  }

  async unpublishModule(module: UserPublishedModule): Promise<GenericAPIResponse | undefined> {
    const { wbShortId, id: moduleId } = module;
    return await checkAxiosResponse(axios.delete(`/api/zoe/app-store/${wbShortId}/unpublish/${moduleId}`, await axiosOptions()));
  }

  async getInstalledBy(module: UserPublishedModule): Promise<
    | {
        users: {
          id: string;
          name: string;
          email: string;
        }[];
      }
    | undefined
  > {
    const { wbShortId, id: moduleId } = module;
    return await checkAxiosResponse(axios.get(`/api/zoe/app-store/${wbShortId}/installedBy/${moduleId}`, await axiosOptions()));
  }
}
