import keyBy from 'lodash/keyBy';
import { ErrorNotification } from '../../../services/error-notification';
import { OrganizationInstanceListModel } from '../../organization-instances/models/organization-instance-list';
import { WbApi, WbInstanceStatus } from '../workbench-api';
import { InstallPackages } from './install-packages';
import { NonOwnedInstance, WbInstance } from './instance';
import { InstanceCreateModel } from './instance-create-model';
import { InstallPackageState, InstanceInstallModel, InstanceInstallPackages } from './instance-install-packages';
import { InstanceReassign } from './instance-reassign';
import { InstanceRename } from './instance-rename';
import { InstanceRemove } from './instance-remove';
import { InstanceSize, instanceSizeProvisionRequest, isInstanceSizeValid } from './instance-size';
import { getInstanceUrl } from '../../shared/instance-mapping';
import { InstanceDailyScheduledPause, InstanceStalePause } from './instance-scheduled-pause';
import { UsersApi } from '../../users/users-api';
import { RcFile } from 'antd/es/upload';
import { arrayBufferToBase64 } from '../../../utils/utils';

export type InstanceModificationState = 'loading' | 'ready' | 'saving';

export interface UpgradeInstance {
  id: string;
  state: InstanceModificationState;
}

export interface UpgradeAllInstances {
  ids: string[];
  names: string[];
  state: 'saving' | 'ready';
}

export interface ResizeInstance extends InstanceSize {
  id: string;
  state: InstanceModificationState;
}

export interface UpdateInstanceLogo {
  shortId: string | undefined;
  logo: RcFile | undefined;
  state: 'saving' | 'ready' | 'loading';
  downloadUrl: string | undefined;
}

export interface InstanceListModel {
  loading: boolean;
  instances: WbInstance[];

  installState: InstallPackageState;
  //only for separate installation dialog
  installPackages: InstallPackages | undefined;
  //both for separate installation dialog and the one integrated in wb creation dialog
  instanceInstallPackages: InstanceInstallPackages | undefined;

  instanceSize: InstanceSize | undefined;
  createInstance: InstanceCreateModel | undefined;
  renameInstance: InstanceRename | undefined;

  //only for separate installation dialog
  staleInstancePause: InstanceStalePause | undefined;
  //both for separate installation dialog and the one integrated in wb creation dialog
  instanceStaleInstancePause: InstanceStalePause | undefined;

  //only for separate installation dialog
  dailyScheduledPause: InstanceDailyScheduledPause | undefined;
  //both for separate installation dialog and the one integrated in wb creation dialog
  instanceDailyScheduledPause: InstanceDailyScheduledPause | undefined;

  updateInstanceLogo: UpdateInstanceLogo | undefined;
  reassignInstance: InstanceReassign | undefined;
  instancesOwnedByOthers: NonOwnedInstance[];
  confirmUpgradeInstance: UpgradeInstance | undefined;
  confirmUpgradeAllInstances: UpgradeAllInstances | undefined;
  confirmResizeInstance: ResizeInstance | undefined;
  confirmRestartInstance: WbInstance | undefined;
  confirmPauseInstance: WbInstance | undefined;
  confirmResumeInstance: WbInstance | undefined;
  confirmRemoveInstance: InstanceRemove | undefined;
  confirmClearInstance: WbInstance | undefined;
}

export function asInstanceCreateUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (
    createInstance: InstanceCreateModel | undefined,
    installPackages: InstallPackages | undefined,
    dailyScheduledPause: InstanceDailyScheduledPause | undefined,
    staleInstancePause: InstanceStalePause | undefined,
    instanceSize: InstanceSize | undefined,
    updateInstanceLogo: UpdateInstanceLogo | undefined
  ) =>
    update({
      ...model(),
      createInstance,
      installPackages,
      dailyScheduledPause,
      staleInstancePause,
      instanceSize,
      updateInstanceLogo,
    });
}

export function asInstanceSizeUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (instanceSize: InstanceSize | undefined) => update({ ...model(), instanceSize });
}

export function asInstanceInstallPackages(model: InstanceListModel): InstanceInstallModel {
  return { install: model.instanceInstallPackages, packages: model.installPackages, state: model.installState };
}

export function asInstanceInstallPackagesUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (installModel: InstanceInstallModel) => {
    const orig = model();
    update({
      ...orig,
      installState: installModel.state ?? orig.installState,
      installPackages: installModel.packages,
      instanceInstallPackages: installModel.install,
    });
  };
}

export function asInstallPackagesUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (installPackages: InstallPackages | undefined) => update({ ...model(), installPackages });
}

export function asInstanceUpgradeUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (confirmUpgradeInstance: UpgradeInstance | undefined) => update({ ...model(), confirmUpgradeInstance });
}

export function asUpgradeAllInstancesUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (confirmUpgradeAllInstances: UpgradeAllInstances | undefined) => update({ ...model(), confirmUpgradeAllInstances });
}

export function asInstanceResizeUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (confirmResizeInstance: ResizeInstance | undefined) => update({ ...model(), confirmResizeInstance });
}

export function asInstanceUpdateLogo(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (updateInstanceLogo: UpdateInstanceLogo) => update({ ...model(), updateInstanceLogo });
}

export function asInstanceReassignUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (reassignInstance: InstanceReassign | undefined) => update({ ...model(), reassignInstance });
}

export function asDailyScheduleUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (dailyScheduledPause: InstanceDailyScheduledPause | undefined) => update({ ...model(), dailyScheduledPause });
}

export function asInstanceDailyScheduleUpdate(model: () => InstanceListModel, update: (_: InstanceListModel) => void) {
  return (dailyScheduledPause: InstanceDailyScheduledPause | undefined) => {
    update({ ...model(), dailyScheduledPause, instanceDailyScheduledPause: dailyScheduledPause });
  };
}

export function initialInstanceList(): InstanceListModel {
  return {
    loading: true,
    installState: { pendingInstallationIds: [] },
    installPackages: undefined,
    instanceSize: undefined,
    createInstance: undefined,
    renameInstance: undefined,
    staleInstancePause: undefined,
    instanceStaleInstancePause: undefined,
    dailyScheduledPause: undefined,
    instanceDailyScheduledPause: undefined,
    reassignInstance: undefined,
    instanceInstallPackages: undefined,
    instances: [],
    instancesOwnedByOthers: [],
    confirmUpgradeInstance: undefined,
    confirmUpgradeAllInstances: undefined,
    confirmResizeInstance: undefined,
    confirmRestartInstance: undefined,
    confirmPauseInstance: undefined,
    confirmResumeInstance: undefined,
    confirmRemoveInstance: undefined,
    confirmClearInstance: undefined,
    updateInstanceLogo: undefined,
  };
}

export function updateInstances(
  model: InstanceListModel,
  instances: WbInstance[],
  instancesOwnedByOthers: NonOwnedInstance[]
): InstanceListModel {
  const instancesById = keyBy(model.instances, ({ id }) => id);

  return {
    ...model,
    instances: instances.map((instance) => ({
      ...instance,
      isOpenInsideUI: instancesById[instance.id]?.isOpenInsideUI,
    })),
    loading: false,
    instancesOwnedByOthers,
  };
}

export function updateStatusOverride<T extends InstanceListModel | OrganizationInstanceListModel>(
  model: T,
  instanceId: string,
  status: WbInstanceStatus | undefined
): T {
  return {
    ...model,
    instances: model.instances.map((i) => (i.id === instanceId ? { ...i, statusOverride: status } : i)),
  };
}

export function updateTakingOwnershipInProgress(
  model: InstanceListModel,
  nonOwnedInstanceId: string,
  performingRetake: boolean
): InstanceListModel {
  return {
    ...model,
    instancesOwnedByOthers: model.instancesOwnedByOthers.map((i) =>
      i.id === nonOwnedInstanceId
        ? {
            ...i,
            performingRetake,
          }
        : i
    ),
  };
}

function makeInstanceAction(
  instanceKey:
    | 'confirmRemoveInstance'
    | 'confirmUpgradeInstance'
    | 'confirmRestartInstance'
    | 'confirmPauseInstance'
    | 'confirmResumeInstance',
  apiCall: (api: WbApi, instance: { id: string }) => Promise<{ ok: boolean } | undefined>,
  tempState: WbInstanceStatus,
  errorMessage: string
) {
  return async <T extends InstanceListModel | OrganizationInstanceListModel>(
    api: WbApi,
    model: () => T,
    update: (_: T) => void,
    refresh: () => Promise<void>
  ): Promise<ErrorNotification | undefined> => {
    const instance = (model() as InstanceListModel & OrganizationInstanceListModel)[instanceKey];
    if (!instance) {
      return;
    }

    update(updateStatusOverride(model(), instance.id, tempState));

    try {
      const res = await apiCall(api, instance);
      if (!res?.ok) {
        return { message: errorMessage };
      }

      await refresh();
    } finally {
      const updatedModel = updateStatusOverride(model(), instance.id, undefined);
      (updatedModel as InstanceListModel & OrganizationInstanceListModel)[instanceKey] = undefined;
      update(updatedModel);
    }
  };
}

export const deleteInstance = makeInstanceAction(
  'confirmRemoveInstance',
  (api, wb) => api.unprovision(wb.id),
  'REMOVING',
  'Error removing instance'
);

export function deleteInstanceCancelled(model: InstanceListModel): InstanceListModel {
  return { ...model, confirmRemoveInstance: undefined };
}

export async function resizeInstance(
  api: WbApi,
  getModel: () => InstanceListModel,
  update: (x: InstanceListModel) => void,
  refresh: () => Promise<void>
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  const { confirmResizeInstance } = model;

  if (!confirmResizeInstance || !isInstanceSizeValid(confirmResizeInstance)) {
    return;
  }

  const isPaused = model.instances.find((i) => i.id === confirmResizeInstance.id)?.nEcsTasks === 0;

  update({
    ...(isPaused ? model : updateStatusOverride(model, confirmResizeInstance.id, 'PROVISIONING')),
    confirmResizeInstance: { ...confirmResizeInstance, state: 'saving' },
  });

  const res = await api.provisionUpdate(confirmResizeInstance.id, {
    resumeIfPaused: false,
    useLastUpdatedImage: true,
    ...instanceSizeProvisionRequest(confirmResizeInstance),
  });
  const getUpdatedModel = () => updateStatusOverride(getModel(), confirmResizeInstance.id, undefined);

  if (!res?.ok) {
    const updatedModel = getUpdatedModel();
    const { confirmResizeInstance } = updatedModel;

    update({
      ...updatedModel,
      confirmResizeInstance: confirmResizeInstance ? { ...confirmResizeInstance, state: 'ready' } : undefined,
    });

    return { message: 'Error resizing instance' };
  }

  try {
    await refresh();
  } finally {
    update({ ...getUpdatedModel(), confirmResizeInstance: undefined });
  }
}

export async function upgradeInstance(
  api: WbApi,
  getModel: () => InstanceListModel,
  update: (x: InstanceListModel) => void,
  refresh: () => Promise<void>
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  const { confirmUpgradeInstance } = model;

  if (!confirmUpgradeInstance) {
    return;
  }

  update({
    ...updateStatusOverride(model, confirmUpgradeInstance.id, 'PROVISIONING'),
    confirmUpgradeInstance: { ...confirmUpgradeInstance, state: 'saving' },
  });

  const res = await api.provisionUpdate(confirmUpgradeInstance.id, undefined);
  const getUpdatedModel = () => updateStatusOverride(getModel(), confirmUpgradeInstance.id, undefined);

  if (!res?.ok) {
    const updatedModel = getUpdatedModel();

    update({
      ...updatedModel,
      confirmUpgradeInstance: updatedModel.confirmUpgradeInstance
        ? {
            ...updatedModel.confirmUpgradeInstance,
            state: 'ready',
          }
        : undefined,
    });
    return { message: 'Error upgrading instance' };
  }

  try {
    await refresh();
  } finally {
    update({
      ...getUpdatedModel(),
      confirmUpgradeInstance: undefined,
    });
  }
}

export async function upgradeInstanceSilently(
  id: string,
  api: WbApi,
  getModel: () => InstanceListModel,
  update: (x: InstanceListModel) => void,
  refresh: () => Promise<void>
): Promise<boolean> {
  update({
    ...updateStatusOverride(getModel(), id, 'PROVISIONING'),
  });

  const output = await api.provisionUpdate(id, undefined);

  try {
    await refresh();
  } finally {
    update({
      ...updateStatusOverride(getModel(), id, undefined),
    });
  }

  return output?.ok || false;
}

export function upgradeInstanceCancelled(model: InstanceListModel): InstanceListModel {
  return { ...model, confirmUpgradeInstance: undefined };
}

export function upgradeAllInstancesCancelled(model: InstanceListModel): InstanceListModel {
  return { ...model, confirmUpgradeAllInstances: undefined };
}

export function resizeInstanceCancelled(model: InstanceListModel): InstanceListModel {
  return { ...model, confirmResizeInstance: undefined };
}

export const restartInstance = makeInstanceAction(
  'confirmRestartInstance',
  (api, wb) => api.restartInstance(wb.id),
  'RESTARTING',
  'Error restarting instance'
);

export function restartInstanceCancelled(model: InstanceListModel): InstanceListModel {
  return { ...model, confirmRestartInstance: undefined };
}

export const pauseInstance = makeInstanceAction(
  'confirmPauseInstance',
  (api, wb) => api.pauseInstance(wb.id),
  'PAUSING',
  'Error restarting instance'
);

export const resumeInstance = makeInstanceAction(
  'confirmResumeInstance',
  (api, wb) => api.resumeInstance(wb.id),
  'PROVISIONING',
  'Error restarting instance'
);

export function startRenameInstance(model: InstanceListModel, instance: WbInstance): InstanceListModel {
  return { ...model, renameInstance: { id: instance.id, name: instance.name, state: 'ready', nameError: '' } };
}

export function updateInstanceRename(model: InstanceListModel, rename: Partial<InstanceRename>): InstanceListModel {
  if (!model.renameInstance) {
    return model;
  }

  return { ...model, renameInstance: { ...model.renameInstance, ...rename } };
}

export function stopRenameInstance(model: InstanceListModel): InstanceListModel {
  return { ...model, renameInstance: undefined };
}

export async function renameInstance(
  api: WbApi,
  getModel: () => InstanceListModel,
  refresh: () => Promise<void>,
  update: (_: InstanceListModel) => void
): Promise<ErrorNotification | undefined> {
  let model = getModel();

  if (!model.renameInstance) {
    return;
  }

  if (!model.renameInstance.name) {
    update(updateInstanceRename(model, { nameError: 'A name is required.' }));
    return;
  }

  const { id, name } = model.renameInstance;

  update(updateInstanceRename(model, { state: 'saving' }));
  const res = await api.renameInstance({ id, name });
  model = getModel();
  if (res?.ok) {
    try {
      await refresh();
    } finally {
      update(stopRenameInstance(getModel()));
    }
  } else {
    update(updateInstanceRename(model, { state: 'ready' }));

    return { message: 'Error renaming instance' };
  }
}

export function clearInstanceStorage(model: InstanceListModel, clear: boolean): InstanceListModel {
  const instance = model.confirmClearInstance;

  if (instance && clear) {
    // created by InstanceList.tsx
    const iframe = document.getElementById('mst-instance-frame') as HTMLIFrameElement | undefined;

    if (iframe) {
      const url = getInstanceUrl(instance.shortId);
      const newUrl = new URL('/clear-site-data', url);
      iframe.src = newUrl.toString();
    }
  }

  return { ...model, confirmClearInstance: undefined };
}

export function setInstanceIsOpen(model: InstanceListModel, instanceId: string, isOpen: boolean): InstanceListModel {
  return {
    ...model,
    instances: model.instances.map((instance) => {
      if (instance.id !== instanceId) {
        return instance;
      }

      return {
        ...instance,
        isOpenInsideUI: isOpen,
      };
    }),
  };
}

export async function openStaleInstancePauseModal(
  api: UsersApi,
  instance: WbInstance,
  getModel: () => InstanceListModel,
  update: (_: InstanceListModel) => void
): Promise<ErrorNotification | undefined> {
  update({
    ...getModel(),
    staleInstancePause: {
      id: instance.id,
      scheduledDays: instance.scheduledPauseDays || window.CONFIG.defaultScheduledPauseDays,
      state: 'loading',
      alertsEnabled: true,
    },
    instanceStaleInstancePause: {
      id: instance.id,
      scheduledDays: instance.scheduledPauseDays || window.CONFIG.defaultScheduledPauseDays,
      state: 'loading',
      alertsEnabled: true,
    },
  });

  try {
    const res = await api.getUserPreferences();
    if (!res?.ok) throw new Error('Server error');
    update(updateStaleInstancePause(getModel(), { state: 'ready', alertsEnabled: res.body.staleInstancesAlertsEnabled }));
  } catch (error) {
    update(updateStaleInstancePause(getModel(), { state: 'ready' }));
    return { message: 'Error retrieving the user preferences' };
  }
}

export function updateStaleInstancePause(model: InstanceListModel, scheduledPause: Partial<InstanceStalePause>): InstanceListModel {
  if (model.staleInstancePause && model.instanceStaleInstancePause) {
    return {
      ...model,
      staleInstancePause: { ...model.staleInstancePause, ...scheduledPause },
      instanceStaleInstancePause: { ...model.instanceStaleInstancePause, ...scheduledPause },
    };
  }

  if (model.staleInstancePause) {
    return { ...model, staleInstancePause: { ...model.staleInstancePause, ...scheduledPause } };
  }

  if (model.instanceStaleInstancePause) {
    return { ...model, instanceStaleInstancePause: { ...model.instanceStaleInstancePause, ...scheduledPause } };
  }

  return model;
}

export function closeStaleInstancePauseModal(model: InstanceListModel): InstanceListModel {
  return { ...model, staleInstancePause: undefined, instanceStaleInstancePause: undefined };
}

export async function staleInstancePauseConfig(
  api: WbApi,
  getModel: () => InstanceListModel,
  refresh: () => Promise<void>,
  update: (_: InstanceListModel) => void
): Promise<ErrorNotification | undefined> {
  let model = getModel();

  if (!model.staleInstancePause) {
    return;
  }

  if (!model.staleInstancePause.scheduledDays) {
    update(updateStaleInstancePause(model, { scheduledDays: window.CONFIG.defaultScheduledPauseDays }));
    return;
  }

  const { id, scheduledDays } = model.staleInstancePause;
  const instance = model.instances.find((i) => i.id === id);

  if (!instance) {
    update(closeStaleInstancePauseModal(getModel()));
    return;
  }

  update(updateStaleInstancePause(model, { state: 'saving' }));

  const res = await api.changeStaleInstancePauseInterval(instance.shortId, scheduledDays);
  model = getModel();
  if (res) {
    try {
      await refresh();
    } finally {
      update(closeStaleInstancePauseModal(getModel()));
    }
  } else {
    update(updateStaleInstancePause(model, { state: 'ready' }));

    return { message: 'Error changing scheduled pause days for instance' };
  }
}

export function updateDailyInstancePause(
  model: InstanceListModel,
  dailyScheduledPause: Partial<InstanceDailyScheduledPause>
): InstanceListModel {
  if (model.dailyScheduledPause && model.instanceDailyScheduledPause) {
    return {
      ...model,
      dailyScheduledPause: { ...model.dailyScheduledPause, ...dailyScheduledPause },
      instanceDailyScheduledPause: { ...model.instanceDailyScheduledPause, ...dailyScheduledPause },
    };
  }

  if (model.dailyScheduledPause) {
    return {
      ...model,
      dailyScheduledPause: { ...model.dailyScheduledPause, ...dailyScheduledPause },
    };
  }

  if (model.instanceDailyScheduledPause) {
    return {
      ...model,
      instanceDailyScheduledPause: { ...model.instanceDailyScheduledPause, ...dailyScheduledPause },
    };
  }

  return model;
}

export function closeDailyInstancePauseModal(model: InstanceListModel): InstanceListModel {
  return { ...model, dailyScheduledPause: undefined, instanceDailyScheduledPause: undefined };
}

export async function dailyInstanceScheduledPause(
  api: WbApi,
  getModel: () => InstanceListModel,
  refresh: () => Promise<void>,
  update: (_: InstanceListModel) => void
): Promise<ErrorNotification | undefined> {
  let model = getModel();
  if (!model.dailyScheduledPause) {
    return;
  }

  if (!model.dailyScheduledPause.timezone) {
    return;
  }

  const { instanceId, ...dailyScheduledPause } = model.dailyScheduledPause;
  const instance = model.instances.find((i) => i.id === instanceId);
  if (!instance) {
    update(closeDailyInstancePauseModal(getModel()));
    return;
  }

  update(updateDailyInstancePause(model, { state: 'saving' }));
  const res = await api.changeDailyScheduledInstancePause(instance.shortId, dailyScheduledPause);
  model = getModel();

  if (res) {
    try {
      await refresh();
    } finally {
      update(closeDailyInstancePauseModal(getModel()));
    }
  } else {
    update(updateDailyInstancePause(model, { state: 'ready' }));

    return { message: 'Error changing scheduled pause days for instance' };
  }
}

export async function dailyInstanceScheduledPauseRemove(
  api: WbApi,
  getModel: () => InstanceListModel,
  refresh: () => Promise<void>,
  update: (_: InstanceListModel) => void
): Promise<ErrorNotification | undefined> {
  let model = getModel();
  if (!model.dailyScheduledPause) {
    return;
  }

  const { instanceId } = model.dailyScheduledPause;
  const instance = model.instances.find((i) => i.id === instanceId);
  if (!instance) {
    update(closeDailyInstancePauseModal(getModel()));
    return;
  }

  update(updateDailyInstancePause(model, { state: 'saving' }));
  const res = await api.removeDailyScheduledInstancePause(instance?.shortId);
  model = getModel();

  if (res) {
    try {
      await refresh();
    } finally {
      update(closeDailyInstancePauseModal(getModel()));
    }
  } else {
    update(updateDailyInstancePause(model, { state: 'loaded' }));

    return { message: 'Error removing scheduled pause days for instance' };
  }
}

export async function updateInstanceLogo(
  api: WbApi,
  getModel: () => InstanceListModel,
  update: (m: InstanceListModel) => void,
  refresh: () => Promise<void>
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  if (!model.updateInstanceLogo || !model.updateInstanceLogo.shortId || !model.updateInstanceLogo.logo) {
    return;
  }

  update({ ...model, updateInstanceLogo: { ...model.updateInstanceLogo, state: 'saving' } });

  const logo = arrayBufferToBase64(await model.updateInstanceLogo.logo.arrayBuffer());

  const res = await api.saveLogo(model.updateInstanceLogo.shortId, logo);

  update({ ...model, updateInstanceLogo: { ...model.updateInstanceLogo, state: 'ready' } });

  if (!res?.ok) {
    return { message: 'Error updating instance logo', description: res?.error };
  }

  await refresh();
}

export async function deleteInstanceLogo(
  api: WbApi,
  getModel: () => InstanceListModel,
  update: (m: InstanceListModel) => void,
  refresh: () => Promise<void>
): Promise<ErrorNotification | undefined> {
  const model = getModel();
  if (!model.updateInstanceLogo || !model.updateInstanceLogo.shortId || !model.updateInstanceLogo.downloadUrl) {
    return;
  }

  update({ ...model, updateInstanceLogo: { ...model.updateInstanceLogo, state: 'saving' } });

  const res = await api.deleteInstanceLogo(model.updateInstanceLogo.shortId);

  update({ ...model, updateInstanceLogo: { ...model.updateInstanceLogo, state: 'ready' } });

  if (!res?.ok) {
    return { message: 'Error removing instance logo', description: res?.error };
  }

  update({ ...model, updateInstanceLogo: { ...model.updateInstanceLogo, downloadUrl: undefined } });

  await refresh();
}
