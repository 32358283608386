import axios from 'axios';
import { checkAxiosResponse, handleAxiosError, throwAxiosError } from '@mst-fe/shared/dist/errors/axios-errors';
import { GenericAPIResponse, axiosOptions } from '../../services/requests';
import { WbUserPreferenceRow } from '@mst-fe/shared';
import { LinkedError } from '@mst-fe/shared/dist/errors/linked-error';
import { ExceptionStatusType } from 'antd/es/result';

export interface User {
  title: string;
  value: string;
  name: string;
  email?: string;
  selectable: boolean;
}

export interface UserData {
  id: string;
  name: string;
  email?: string;
}

export interface GroupData {
  groupId: string;
  groupName: string;
  members: UserData[];
}

export interface GetGroupsResponse {
  allUserGroups: GroupData[];
  canAssignToAnyone?: boolean;
}

export interface UserRole {
  isAdmin: boolean;
}

type ApiResponse = {
  ok: boolean;
  statusCode: number;
};

export class UsersApi {
  async getUsers(): Promise<GetGroupsResponse | undefined> {
    return await checkAxiosResponse(axios.get<GetGroupsResponse>('/api/workbench/ownership/my-groups', await axiosOptions()));
  }

  async getUserRole(): Promise<UserRole | undefined> {
    return await checkAxiosResponse(axios.get<UserRole>('/api/zoe/user/role', await axiosOptions()));
  }

  async getUserGitlabApiKey(): Promise<GenericAPIResponse<{ gitLabApiKey: string }> | undefined> {
    return await checkAxiosResponse(
      axios.get<GenericAPIResponse<{ gitLabApiKey: string }>>('/api/zoe/user/gitlab-apikey', await axiosOptions())
    );
  }

  async putUserGitlabApiKey(apiKey: string): Promise<GenericAPIResponse | undefined> {
    return await checkAxiosResponse(axios.put<GenericAPIResponse>('/api/zoe/user/gitlab-apikey', { apiKey }, await axiosOptions()));
  }

  async checkIn() {
    return await checkAxiosResponse(
      axios.post<{ featureFlags: string[]; workbenchAllowed: boolean; isAdmin: boolean; ok: boolean } | undefined>(
        '/api/zoe/user/check-in',
        {},
        await axiosOptions()
      )
    );
  }

  async getUserPreferences() {
    return await checkAxiosResponse(
      axios.get<{ body: WbUserPreferenceRow; ok: boolean } | undefined>('/api/zoe/user/preferences', await axiosOptions())
    );
  }

  async saveUserPreferences(userPreferences: WbUserPreferenceRow) {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean } | undefined>('/api/zoe/user/preferences', userPreferences, await axiosOptions())
    );
  }

  async cancelUserResourcesDeletion(params: {
    userId: string;
  }): Promise<{ ok: false; statusCode: ExceptionStatusType; error: string } | { ok: true }> {
    const responseOrError = await handleAxiosError(
      axios.put<ApiResponse>(`/api/zoe/user/cancel-user-resources-deletion/${params.userId}`, undefined, await axiosOptions())
    );

    if (responseOrError instanceof LinkedError) {
      console.error(responseOrError.cause.responseData);

      if (
        responseOrError.cause.responseStatus === 404 ||
        responseOrError.cause.responseStatus === 403 ||
        responseOrError.cause.responseStatus === 500
      ) {
        const errorMessage =
          responseOrError.cause.responseStatus === 404
            ? 'The user is not in the list of users to be deleted.'
            : responseOrError.cause.responseStatus === 403
              ? 'You are not authorised to perform this action.'
              : 'Sorry, something went wrong.';

        return {
          ok: false,
          statusCode: responseOrError.cause.responseStatus,
          error: errorMessage,
        };
      }

      throw responseOrError;
    }

    return { ok: true };
  }
}
