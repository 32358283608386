import React from 'react';
import { Link } from 'react-router-dom';

import { MenuProps, Button } from 'antd';
import { AppstoreOutlined, FolderOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import { CodeBracketSquareIcon, WindowIcon } from '@heroicons/react/24/outline';

import { ServicesListModel } from '../models/services-list';
import { APPLICATIONS_ITEM_KEY, APPLICATIONS_ITEM_KEY_PREFIX } from '../../../side-nav/SideNav';
import { WbService } from '../models/service';

import styles from './ServicesNav.module.css';
import sharedStyles from '../../../side-nav/SideNav.shared.module.css';
import { MenuItemGroupType, MenuItemType } from 'antd/es/menu/interface';

function ServicesNav() {
  return (
    <div data-testid="side-nav-applications">
      <div className="sidebar-nav-item-title-icon-container">
        <div>Applications</div>
        <Link to={'/published-manifests?appType=app'}>
          <Button icon={<ShoppingCartOutlined />} type="text" />
        </Link>
      </div>
    </div>
  );
}

function ServicesNavItem({ model }: { model: WbService }) {
  const path = `/applications/${model.id}`;

  return (
    <div>
      <div className={sharedStyles.navLabel}>
        <Link to={path}>{model.name}</Link>
        <div className={`${styles.serviceActivation} sidebar-nav-item-service-status`}>{model.status}</div>
      </div>
    </div>
  );
}

function getServicesNavItems(services: WbService[], urlGroupName = '', loading: boolean) {
  if (!services.length) {
    return [
      {
        label: loading ? 'Loading…' : 'No Apps',
        key: `${APPLICATIONS_ITEM_KEY_PREFIX}${urlGroupName}/empty`,
        className: styles.emptyMenuItem,
        disabled: true,
      },
    ];
  }

  return services.map((service) => ({
    label: <ServicesNavItem model={service} />,
    icon: service.logo ? (
      <img src={service.logo} alt="logo" style={{ height: '16px', width: '16px', objectFit: 'contain' }} />
    ) : service.type === 'streamlit' ? (
      <WindowIcon height={16} width={16} />
    ) : (
      <CodeBracketSquareIcon height={16} width={16} />
    ),
    key: `${APPLICATIONS_ITEM_KEY_PREFIX}${service.id}`,
    title: service.name,
  }));
}

function ServicesNavGroup(
  { services, groupName }: { services: WbService[]; groupName: string },
  loading: boolean
): MenuItemGroupType<MenuItemType> {
  return {
    label: (
      <div className={'sidebar-group-item'}>
        <FolderOutlined /> {groupName}
      </div>
    ),
    key: `${APPLICATIONS_ITEM_KEY_PREFIX}${groupName}`,
    type: 'group',
    children: getServicesNavItems(services, groupName, loading),
    className: styles.serviceGroup,
  };
}

export function getServicesNav({ model }: { model: ServicesListModel }): Required<MenuProps>['items'][number] {
  const sharedWithMe = model.services.filter((s) => !s.isInstalledFromStore && !s.ownedByUser);
  const installed = model.services.filter((s) => s.isInstalledFromStore);
  const myApps = model.services.filter((s) => !s.isInstalledFromStore && s.ownedByUser);
  const groups = [
    { groupName: 'My Apps', services: myApps },
    { groupName: 'Installed Apps', services: installed },
    { groupName: 'Shared With Me', services: sharedWithMe },
  ];

  return {
    label: <ServicesNav />,
    key: APPLICATIONS_ITEM_KEY,
    icon: <AppstoreOutlined />,
    children: groups.map((g) => ServicesNavGroup(g, model.loading)),
  };
}
