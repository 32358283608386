import axios from 'axios';
import { checkAxiosResponse, handleAxiosError } from '@mst-fe/shared/dist/errors/axios-errors';
import { axiosOptions } from '../../services/requests';
import { InstanceDailyScheduledPause } from './models/instance-scheduled-pause';
import { LinkedError } from '@mst-fe/shared/dist/errors/linked-error';

// note: no custom images support

export type WbInstanceStatus =
  | 'PROVISIONING'
  | 'REMOVING'
  | 'PROVISIONED'
  | 'PROVISIONING_ERROR'
  | 'REMOVING_ERROR'
  | 'RESTARTING'
  | 'PAUSING'
  | 'PAUSED'
  | 'DELETED';

export type WbEcsTaskState = 'STOPPED' | 'PENDING' | 'RUNNING';

export type WbDeploymentType = 'default-workbench' | 'midas' | 'bellport';

export interface WbInstanceData {
  id: string;
  shortId: string;
  currentStatus: WbInstanceStatus;
  created_at: string;
  uiData?: {
    name?: string;
  };
  provisioningInfo: {
    progress?: number;
  };
  awsInfo: {
    ECRImageId: string;
  };
  canUpdate: boolean;
  runningJobs: number;
  defaultClusterId: string | undefined;
  defaultServerId: string | undefined;
  mem: number;
  cpu: number;
  diskSize: number;
  nEcsTasks: number;
  awsCurrentTaskStatus: WbEcsTaskState | null | undefined;
  awsCurrentTaskId: string | null | undefined;
  consecutiveFailures: number | null | undefined;
  scheduledPauseDays: number;
  deploymentType: WbDeploymentType;
  logoDownloadUrl: string | undefined;
}

export interface WbPackageInstallation {
  id: string;
  instanceId: string;
  aptPackages: string[];
  condaPackages: string[];
  currentStatus: 'INSTALLING' | 'SUCCESS' | 'REQUESTED' | 'FAILED';
}

export interface WbUpdateNotification {
  userId: string;
  shortId: string;
  cutoffDate: string;
  instanceId: string;
}

export interface WbScript {
  id: string;
  displayName: string;
  description: string;
  icon: string;
  createdAt: string;
  updatedAt: string;
  alwaysEnabled: number | null;
  internalKey: string | null;
  isUnique: number | null;
}

export interface AptSearchResult {
  name: string;
  description: string;
}

export interface InstanceUpdateNotification {
  shortId: string;
  cutoffDate: string;
  instanceId: string;
}

export interface WbProvisionRequest {
  scripts: string[];
  name?: string;
  aptPackages: string[];
  condaPackages: string[];
  mem: number;
  cpu: number;
  diskSize: number;
  dailyScheduledPause?: Pick<InstanceDailyScheduledPause, 'fromTime' | 'toTime' | 'isPausedOnWeekends' | 'timezone'>;
  staleInstancePause?: { scheduledPauseDays: number };
  logo: string | undefined;
}

export interface WbUpdateRequest {
  mem: number;
  cpu: number;
  diskSize: number;
  useLastUpdatedImage?: boolean;
  resumeIfPaused?: boolean;
}

export type UserAssignmentSpecifier = { userId: string } | { email: string };

export class WbApi {
  async getInstances(config?: { onlyProvisioned: boolean }) {
    return await checkAxiosResponse(
      axios.get<WbInstanceData[]>(
        '/api/workbench/instances/user?extendedInfo=true' + (config?.onlyProvisioned ? '&onlyProvisioned=true' : ''),
        await axiosOptions()
      )
    );
  }

  async getInstanceScripts() {
    return await checkAxiosResponse(axios.get<WbScript[]>('/api/workbench/scripts/all-available-scripts', await axiosOptions()));
  }

  async getRunningServices() {
    return await checkAxiosResponse(axios.get<WbInstanceData[]>('/api/workbench/instances/user?extendedInfo=true', await axiosOptions()));
  }

  async searchPackages(term: string) {
    return await checkAxiosResponse(
      axios.get<AptSearchResult[]>(`/api/workbench/scripts/search-packages?text=${encodeURIComponent(term)}`, await axiosOptions())
    );
  }

  async provisionNew(data: WbProvisionRequest): Promise<{ ok: boolean; error?: string } | undefined> {
    const responseOrError = await handleAxiosError(
      axios.post<{ ok: boolean; error?: string }>(
        '/api/workbench/provisioning/provision',
        {
          mem: data.mem,
          name: data.name,
          cpu: data.cpu,
          diskSize: data.diskSize,
          initData: { scripts: data.scripts, aptPackages: data.aptPackages, condaPackages: data.condaPackages },
          dailyScheduledPause: data.dailyScheduledPause,
          staleInstancePause: data.staleInstancePause,
          logo: data.logo,
        },
        await axiosOptions()
      )
    );
    if (responseOrError instanceof LinkedError) {
      if (responseOrError.cause.responseStatus === 400) {
        return responseOrError.cause.responseData as { ok: boolean; error: string };
      }

      return undefined;
    }

    return responseOrError;
  }

  async saveLogo(wbId: string, logo: string) {
    return checkAxiosResponse(
      axios.post<{ ok: boolean; error?: string }>(`/api/workbench/instances/${wbId}/logo`, { logo }, await axiosOptions())
    );
  }

  async deleteInstanceLogo(wbId: string): Promise<{ ok: boolean; error?: string } | undefined> {
    return await checkAxiosResponse(axios.delete<{ ok: boolean }>(`/api/workbench/instances/${wbId}/logo`, await axiosOptions()));
  }

  async provisionUpdate(uuid: string, updateRequest: WbUpdateRequest | undefined): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(
        '/api/workbench/provisioning/update',
        {
          uuid,
          ...updateRequest,
        },
        await axiosOptions()
      )
    );
  }

  async restartInstance(uuid: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(
        '/api/workbench/provisioning/restart-instance',
        {
          uuid,
        },
        await axiosOptions()
      )
    );
  }

  async pauseInstance(uuid: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(
        '/api/workbench/provisioning/pause-instance',
        {
          uuid,
        },
        await axiosOptions()
      )
    );
  }

  async resumeInstance(uuid: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(
        '/api/workbench/provisioning/resume-instance',
        {
          uuid,
        },
        await axiosOptions()
      )
    );
  }

  async unprovision(uuid: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(
        '/api/workbench/provisioning/unprovision',
        {
          uuid,
        },
        await axiosOptions()
      )
    );
  }

  async renameInstance(data: { id: string; name: string }): Promise<{ ok: boolean } | undefined> {
    const res = await checkAxiosResponse(
      axios.put<{ ok: boolean }>(
        `/api/workbench/instances/ui-data/${data.id}`,
        {
          uuid: data.id,
          name: data.name,
        },
        await axiosOptions()
      )
    );

    if (res !== undefined) {
      return { ok: true };
    }

    return undefined;
  }

  async getInstanceName(wbShortId: string): Promise<string | undefined> {
    return await checkAxiosResponse(axios.get<string>('/api/workbench/instances/name/' + wbShortId, await axiosOptions()));
  }

  async reassignInstance(data: { id: string; user: UserAssignmentSpecifier }): Promise<{ ok: boolean } | undefined> {
    const res = await checkAxiosResponse(
      axios.post<{ ok: boolean }>(`/api/workbench/ownership/change-object-ownership/${data.id}`, data.user, await axiosOptions())
    );

    if (res !== undefined) {
      return { ok: true };
    }

    return undefined;
  }

  async getInstancePackages(wbShortId: string): Promise<WbPackageInstallation[] | undefined> {
    return await checkAxiosResponse(
      axios.get<WbPackageInstallation[]>(`/api/workbench/instances/apt-packages-installation/${wbShortId}`, await axiosOptions())
    );
  }

  async installPackages(data: {
    instanceId: string;
    aptPackages: string[];
    condaPackages: string[];
  }): Promise<{ ok: boolean; installationId: string } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean; installationId: string }>(
        '/api/workbench/instances/apt-packages-installation',
        {
          instanceId: data.instanceId,
          aptPackages: data.aptPackages,
          condaPackages: data.condaPackages,
        },
        await axiosOptions()
      )
    );
  }

  async getUpdateNotifications(): Promise<InstanceUpdateNotification[] | undefined> {
    return await checkAxiosResponse(
      axios.get<InstanceUpdateNotification[]>('/api/workbench/instances/update-instance-notifications', await axiosOptions())
    );
  }

  async ignoreUpdateNotification(shortId: string): Promise<object | undefined> {
    return await checkAxiosResponse(
      axios.post<object>('/api/workbench/instances/update-instance-notification/ignore', { shortId }, await axiosOptions())
    );
  }

  async getInstancesOwnedByOthers(): Promise<{ instances: WbInstanceData[] } | undefined> {
    return await checkAxiosResponse<{ instances: WbInstanceData[] }>(
      axios.get('/api/workbench/instances/assigned-to-others/', await axiosOptions())
    );
  }

  async getWorkbenchStoredFileSummary(instanceId: string): Promise<{ files: string[]; totalSize: number } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ files: string[]; totalSize: number }>(
        `/api/workbench/instances/get-stored-file-summary`,
        { instanceId },
        await axiosOptions()
      )
    );
  }

  async changeStaleInstancePauseInterval(wbShortId: string, scheduledPauseDays: number): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(
        `/api/workbench/instances/${wbShortId}/stale-pause-instance-config`,
        {
          scheduledPauseDays,
        },
        await axiosOptions()
      )
    );
  }

  async changeDailyScheduledInstancePause(
    wbShortId: string,
    dailyScheduledPause: Pick<InstanceDailyScheduledPause, 'timezone' | 'isPausedOnWeekends' | 'fromTime' | 'toTime'>
  ): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.post<{ ok: boolean }>(
        `/api/workbench/instances/${wbShortId}/daily-scheduled-pause-instance`,
        {
          dailyScheduledPause: { ...dailyScheduledPause, state: undefined },
        },
        await axiosOptions()
      )
    );
  }

  async removeDailyScheduledInstancePause(wbShortId: string): Promise<{ ok: boolean } | undefined> {
    return await checkAxiosResponse(
      axios.delete<{ ok: boolean }>(`/api/workbench/instances/${wbShortId}/daily-scheduled-pause-instance`, await axiosOptions())
    );
  }

  async getDailyScheduledInstancePause(wbShortId: string): Promise<Omit<InstanceDailyScheduledPause, 'state' | 'dateError'> | undefined> {
    return await checkAxiosResponse(
      axios.get(`/api/workbench/instances/${wbShortId}/daily-scheduled-pause-instance`, await axiosOptions())
    );
  }
}
